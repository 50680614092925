import React from "react"
import Box from "@material-ui/core/Box"

export default function Transformation() {
  return (
    <Box bgcolor="rgba(37, 37, 39, 0.98)" width={1}>
      <Box width={4 / 5} mx="auto" py={7}>
        <Box
          fontSize="h2.fontSize"
          fontWeight={500}
          letterSpacing={1.5}
          lineHeight={1.5}
          color="#fff"
          py={2}
        >
          How The Dashboard Company can help:
        </Box>
        <Box
          fontSize="h5.fontSize"
          fontWeight={500}
          letterSpacing={1.5}
          lineHeight={1.5}
          color="#fff"
          pb={2}
        >
          Working with The Dashboard Company is easy. We meet you where you are
          and provide products and services to help you get the most out of your
          data. A dashboard needs to be visually appealing to encourage user
          adoption and provide the right accurate data. Finally, dashboards must
          provide recommendations or insights to help drive better decision
          making.
        </Box>
      </Box>
    </Box>
  )
}
