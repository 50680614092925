import React from "react"
import Box from "@material-ui/core/Box"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

export default () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "highcharts.png" }) {
        childImageSharp {
          fluid(maxWidth: 600, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)
  return (
    <Box
      display="flex"
      flexDirection="row"
      flexWrap="wrap"
      justifyContent="center"
      width={4 / 5}
      mx="auto"
      my={6}
    >
      <Box
        fontSize="h3.fontSize"
        fontWeight={500}
        letterSpacing={1.5}
        lineHeight={1.5}
        width={1}
        textAlign="center"
        pb={5}
      >
        Dashboard Development Services
      </Box>
      <Box width={{ xs: 1, sm: 1, md: 1 / 2 }} alignSelf="center" boxShadow={3}>
        <Img fluid={data.file.childImageSharp.fluid} alt="chart" />
      </Box>
      <Box fontSize="h6.fontSize" letterSpacing={1.5} lineHeight={1.5}>
        <br />
        <h4> Which camp are you in? </h4>I have data and want dashboards to make
        better decisions with. I don&apos;t have an in-house resource or
        platform to use.
        <h4>Or</h4>
        I have a reporting platform and need help building the dashboards to
        make sense of the data using that platform.
        <br />
        <h3>WE CAN HELP!</h3>
        Dazzle your team, partners and customers with dashboards. Make decisions
        faster. Discover hidden and novel insights from your data.
        <br />
        <br />
        We understand no one knows your business as intimately as you; so we
        give you the power of a team of data experts to help you knit together
        data from multiple data sources, shape data throughout your organization
        and ultimately visualize that data in the best possible way based on the
        project.
        <br />
        <h4>Types of Dashboards</h4>
        <Box fontSize="h6.fontSize" letterSpacing={1.5} lineHeight={1.5}>
          <strong> Strategic </strong> - A dashboard focused on monitoring
          long-term company strategies by analyzing and benchmarking a wide
          range of critical trend-based information.
          <br />
          <br />
          <strong> Operational </strong>- A business intelligence tool that
          exists to monitor, measure and manage processes or operations with a
          shorter or more immediate time scale.
          <br />
          <br />
          <strong> Analytical </strong> - A dashboards containing large streams
          of comprehensive data that allow analysts to drill down and extract
          insights to help the company to progress at an executive level. <br />
          <br />
          <strong>Tactical </strong> - An information-rich dashboard best suited
          to mid-management and help in formulating growth strategies based on
          trends, strengths, and weaknesses across departments.
          <br />
          <br />
          Dashboard development is the life line of our company. You have data,
          now let's extract as much value out of that data as possible.
        </Box>
      </Box>
    </Box>
  )
}
