import React from "react"
import Box from "@material-ui/core/Box"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import Button from "@material-ui/core/Button"
import { Link } from "gatsby"
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  buttonHero: {
    fontSize: "1.5rem",
    letterSpacing: "0.15rem",
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5),
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
}))

export default () => {
  const classes = useStyles()
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "iot_dashboard.png" }) {
        childImageSharp {
          fluid(maxWidth: 750, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)
  return (
    <Box
      display="flex"
      flexDirection="row"
      flexWrap="wrap"
      alignItems="center"
      justifyContent="space-between"
      width={4 / 5}
      mx="auto"
      my={9}
    >
      <Box
        fontSize="h1.fontSize"
        fontWeight={500}
        letterSpacing={1.5}
        lineHeight={1.5}
        width={{ xs: 1, sm: 1, md: 1 / 2 }}
      >
        Services
        <Box fontSize="h5.fontSize" letterSpacing={1.5} lineHeight={1.5}>
          We are an extension of your team helping you turn raw data into
          valuable insights.
        </Box>
        <Box my={1} display={{ xs: "none", sm: "none", md: "block" }}>
          <Button
            variant="contained"
            color="primary"
            component={Link}
            size="large"
            to="/contact"
            className={classes.buttonHero}
          >
            Work With Us
          </Button>
        </Box>
      </Box>
      <Box
        width={{ xs: 1, sm: 1, md: 2 / 5, lg: 2 / 5 }}
        alignSelf="flex-end"
        boxShadow={3}
        mt={{ xs: 5, sm: 5, md: 0 }}
      >
        <Img fluid={data.file.childImageSharp.fluid} alt="chart" />
      </Box>
      <Box my={1} display={{ xs: "block", sm: "block", md: "none" }}>
        <Button
          variant="contained"
          color="primary"
          component={Link}
          size="large"
          to="/contact"
          className={classes.buttonHero}
        >
          Work With Us
        </Button>
      </Box>
    </Box>
  )
}
