import React from "react"
import SEO from "../components/seo"
import SolutionsHero from "../components/solutionsHero"
import Transformation from "../components/transformation"
import Dashboard from "../components/dashboard"
import Engineering from "../components/engineering"
import Consulting from "../components/consulting"
import CTA from "../components/cta"
import Layout from "../components/layout/layout"
import Header from "../components/header"
import MobileNav from "../components/mobileNav"

const Solutions = () => (
  <>
    <SEO title="Solutions" />
    <Header />
    <MobileNav />
    <Layout>
      <SolutionsHero />
      <Transformation />
      <Dashboard />
      <Engineering />
      <Consulting />
      <CTA />
    </Layout>
  </>
)

export default Solutions
