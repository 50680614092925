import React from "react"
import Box from "@material-ui/core/Box"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

export default () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "data_engineering.png" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)
  return (
    <Box width={4 / 5} mx="auto" py={6}>
      <Box
        display="flex"
        flexDirection="row"
        flexWrap="wrap"
        justifyContent="space-between"
      >
        <Box
          width={{ xs: 1, sm: 1, md: 2 / 5, lg: 2 / 5 }}
          display={{ xs: "block", sm: "block", md: "none" }}
          boxShadow={3}
        >
          <Img
            fluid={data.file.childImageSharp.fluid}
            alt="Engineering and Code"
          />
        </Box>
        <Box width={{ xs: 1, sm: 1, md: 1 / 2 }}>
          <Box
            fontSize="h3.fontSize"
            fontWeight={500}
            letterSpacing={1.5}
            lineHeight={1.5}
            mt={{ xs: 4, sm: 4, md: 0 }}
          >
            Data Engineering Services
          </Box>
          <Box
            fontSize="h6.fontSize"
            letterSpacing={1.5}
            lineHeight={1.5}
            py={3}
          >
            Most companies have a data processing problem. With data coming from
            multiple data sources, mismatched formats, structured and
            unstructured data sometimes it gets ugly. Our engineers and data experts help transform your data and make it usable and useful.
            <br />
            <br />
          </Box>
          <Box fontSize="h6.fontSize" letterSpacing={1.5} lineHeight={1.5}>
            <strong>Data Wrangling</strong>- Data wrangling is the process of
            cleaning and unifying messy and complex data sets for easy access
            and analysis.
            <br />
            <br />
            <strong>Statistical Analysis</strong>- Statistical analysis is the
            process of generating statistics from stored data and analyzing the
            results to deduce or infer meaning about the underlying dataset.
            <br />
            <br />
            <strong>Data Modeling</strong>- Data modeling is the process of
            documenting a complex software system design as an easily understood
            diagram, using text and symbols to represent the way data needs to
            flow.
            <br />
            <br />
            <strong>ETL/ Data Transformation</strong>- ETL is a type of data
            integration that refers to the three steps (extract, transform,
            load) used to blend data from multiple sources.
            <br />
          </Box>
        </Box>
        <Box
          width={{ xs: 1, sm: 1, md: 2 / 5, lg: 2 / 5 }}
          display={{ xs: "none", sm: "none", md: "block" }}
        >
          <Img
            fluid={data.file.childImageSharp.fluid}
            alt="Engineering and Code"
          />
        </Box>
      </Box>
    </Box>
  )
}
