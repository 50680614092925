import React from "react"
import Box from "@material-ui/core/Box"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

export default () => {
  const data = useStaticQuery(graphql`
    query {
      file(relativePath: { eq: "consulting.png" }) {
        childImageSharp {
          fluid(maxWidth: 700, quality: 100) {
            ...GatsbyImageSharpFluid_withWebp_tracedSVG
          }
        }
      }
    }
  `)
  return (
    <Box
      display="flex"
      flexDirection="row"
      flexWrap="wrap"
      justifyContent="space-between"
      width={4 / 5}
      mx="auto"
      my={4}
    >
      <Box
        width={{ xs: 1, sm: 1, md: 2 / 5, lg: 2 / 5 }}
        order={1}
        my={{ xs: 4, sm: 4, md: 0 }}
      >
        <Img fluid={data.file.childImageSharp.fluid} alt="workflow" />
      </Box>
      <Box width={{ xs: 1, sm: 1, md: 1 / 2 }} order={2}>
        <Box
          fontSize="h3.fontSize"
          fontWeight={500}
          letterSpacing={1.5}
          lineHeight={1.5}
        >
          Consulting Services
        </Box>
        <Box fontSize="h6.fontSize" letterSpacing={1.5} lineHeight={1.5}>
          <br />
          The Dashboard Company meets you where you are. Our data and dashboard
          experts can build in your existing technology or leverage a suite of
          proprietary and partner technologies to deliver dashboards and
          outcomes you want.
          <br />
          <h4>How The Dashboard Company can help</h4>
          <Box fontSize="h6.fontSize" letterSpacing={1.5} lineHeight={1.5}>
            <strong> Scorecard Development </strong>- We partner with you and
            create a Scorecard system for tracking and monitoring the quality of
            your operations. Scorecards are powerful tools for identifying
            quality gaps, centers of excellence, target opportunities and
            benchmark progress.
            <br />
            <br />
            <strong> Key Performance Indicators and Measures(KPI) </strong>- Our
            strategy consultants help you with identifying what matters most in
            your organization, what metrics to use to track in your operation
            and how to capture the best possible data to give you visibility
            into your KPI’s. We learn from your business leaders about your
            operation, document the intended outcomes and build dashboard to
            support leadership decision making.
            <br />
            <br />
            <strong> Data Model Design</strong> - Defining an institutional or
            organizational data model is a natural first step in the data
            journey. Using the dashboard canvas, we help you define a scalable
            data model to help with current reporting and dashboard efforts
            build for the future.
            <br />
            <br />
            <strong>Data Discovery Missions </strong> - Organizations have
            untapped resources and data sets with tremendous value locked within
            them. Our Data Discovery Missions apply a Seal Team approach toward
            identifying new and novel ideas, insights and opportunities about
            data already in your company’s possession. Our team of consultants
            go into research mode and deploy deep analysis, statistical modeling
            and data science strategies to unlock opportunities for your
            organization. Data Discovery Missions have resulted in new joint
            ventures, new products, new services, massive cost savings
            opportunities, optimized operations and new revenue opportunities.
            <br />
            <br />
          </Box>
        </Box>
      </Box>
    </Box>
  )
}
